import React, { useEffect, useState } from 'react';

const BlockTypoViewer = () => {
  const [sansSerif, setSansSerif] = useState('serif');
  const [elementType, setElementType] = useState('title');
  const [condReg, setCondReg] = useState('cond');

  const [alphabet, setAlphabet] = useState('latin');

  const [modifiers, setModifiers] = useState(['latin', 'serif', 'title', 'cond']);
  const [modifierClass, setModifierClass] = useState('latin-serif-title-cond');

  useEffect(() => {
    // Fonts that are not available
    if (
      alphabet === 'thai' &&
      sansSerif === 'serif' &&
      elementType === 'text' &&
      condReg === 'reg'
    ) {
      setCondReg('cond');
    }
  }, [alphabet, sansSerif, elementType, condReg]);

  useEffect(() => {
    setModifiers([alphabet, sansSerif, elementType, condReg]);
  }, [alphabet, sansSerif, elementType, condReg]);

  useEffect(() => {
    const modifierToString = modifiers.join('-');
    setModifierClass(modifierToString);
  }, [modifiers]);

  const topicList = {
    category1: [
      {
        id: 1,
        tabHeading: 'Serif',
        type: 'serif',
      },
      {
        id: 2,
        tabHeading: 'Sans',
        type: 'sans',
      },
    ],
    category2: [
      {
        id: 11,
        tabHeading: 'Title',
        type: 'title',
      },
      {
        id: 22,
        tabHeading: 'Text',
        type: 'text',
      },
    ],
    category3: [
      {
        id: 111,
        tabHeading: 'Cond',
        type: 'cond',
      },
      {
        id: 222,
        tabHeading: 'Reg',
        type: 'reg',
      },
    ],
    sloganList: ['Masterpiece of Intelligence.'],
    typeList: [
      {
        id: 1,
        type: 'latin',
      },
      {
        id: 2,
        type: 'thai',
      },
      {
        id: 3,
        type: 'chinese',
      },
    ],
  };

  return (
    <section className="section section-typography">
      <div className="section-typography__wrap">
        {topicList && (
          <>
            <div className="section-typography__tab-section">
              <ul role="tablist" className="section-typography__topics">
                {topicList.typeList && topicList.typeList.length && (
                  <li className="section-typography__category-topics-container">
                    <ul className="section-typography__category-topics">
                      {topicList.typeList.map(({ id, type }, index) => (
                        <>
                          <li
                            role="tab"
                            tabIndex="0"
                            className={`section-typography__topic section-typography__topic--vertical${
                              alphabet === type ? ' section-typography__topic--is-active-left' : ''
                            }`}
                            aria-selected={alphabet === type ? 'true' : 'false'}
                            onClick={() => setAlphabet(type)}
                            onKeyDown={(e) => {
                              if (e.keyCode === 13 || e.keyCode === 32) {
                                e.preventDefault();
                                setAlphabet(type);
                              }
                            }}
                            key={`tab-${id}`}
                          >
                            {type}
                          </li>
                        </>
                      ))}
                    </ul>
                  </li>
                )}
              </ul>
            </div>
            <div className="section-typography__tab-section">
              <ul
                role="tablist"
                className="section-typography__topics"
                aria-hidden={alphabet === 'chinese'}
              >
                {topicList.category1 && topicList.category1.length && (
                  <li className="section-typography__category-topics-container">
                    <ul className="section-typography__category-topics">
                      {topicList.category1.map(({ id, tabHeading, type }, index) => (
                        <li
                          role="tab"
                          tabIndex="0"
                          className={`section-typography__topic${
                            sansSerif === type ? ' section-typography__topic--is-active' : ''
                          }`}
                          aria-selected={sansSerif === type ? 'true' : 'false'}
                          onClick={() => setSansSerif(type)}
                          onKeyDown={(e) => {
                            if (e.keyCode === 13 || e.keyCode === 32) {
                              e.preventDefault();
                              setSansSerif(type);
                            }
                          }}
                          key={`tab-${id}`}
                        >
                          {tabHeading}
                        </li>
                      ))}
                    </ul>
                  </li>
                )}
                {topicList.category2 && topicList.category2.length && (
                  <li className="section-typography__category-topics-container">
                    <ul className="section-typography__category-topics">
                      {topicList.category2.map(({ id, tabHeading, type }, index) => (
                        <li
                          role="tab"
                          tabIndex="0"
                          className={`section-typography__topic${
                            elementType === type ? ' section-typography__topic--is-active' : ''
                          }`}
                          aria-selected={elementType === type ? 'true' : 'false'}
                          onClick={() => setElementType(type)}
                          onKeyDown={(e) => {
                            if (e.keyCode === 13 || e.keyCode === 32) {
                              e.preventDefault();
                              setElementType(type);
                            }
                          }}
                          key={`tab-${id}`}
                        >
                          {tabHeading}
                        </li>
                      ))}
                    </ul>
                  </li>
                )}
                {topicList.category3 && topicList.category3.length && (
                  <li className="section-typography__category-topics-container">
                    <ul className="section-typography__category-topics">
                      {topicList.category3.map(({ id, tabHeading, type }, index) => (
                        <li
                          role="tab"
                          tabIndex="0"
                          className={`section-typography__topic${
                            condReg === type ? ' section-typography__topic--is-active' : ''
                          } ${
                            alphabet === 'thai' &&
                            sansSerif === 'serif' &&
                            elementType === 'text' &&
                            type === 'reg' &&
                            'section-typography__topic--disabled'
                          }`}
                          aria-selected={condReg === type ? 'true' : 'false'}
                          onClick={() => setCondReg(type)}
                          onKeyDown={(e) => {
                            if (e.keyCode === 13 || e.keyCode === 32) {
                              e.preventDefault();
                              setCondReg(type);
                            }
                          }}
                          key={`tab-${id}`}
                        >
                          {tabHeading}
                        </li>
                      ))}
                    </ul>
                  </li>
                )}
              </ul>
              <div role="tabpanel" className="section-typography__content">
                {alphabet === 'chinese' ? (
                  <div className="section-typography__text section-typography__text--chinese">
                    <p className="section-typography__chinese-p">Serif Title Condensed Regular</p>
                    <div className="section-typography__chinese section-typography__chinese--1" />
                    <p className="section-typography__chinese-p">Sans Title Condensed Light</p>
                    <div className="section-typography__chinese section-typography__chinese--2" />
                    <p className="section-typography__chinese-p">Sans Text Light</p>
                    <div className="section-typography__chinese section-typography__chinese--3" />
                  </div>
                ) : (
                  topicList.sloganList &&
                  topicList.sloganList.length && (
                    <div
                      className={`section-typography__text section-typography__text--${alphabet} section-typography__text--${modifierClass}`}
                    />
                  )
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </section>
  );
};

export default BlockTypoViewer;
